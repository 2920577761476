.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.noStyle {
  list-style-type: none;
}

.article {
  color: white;
  font-size: 14px;
}

.navLink:hover {
  color: #1f599c;
}

.iframeVideo {
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.fixbutton {
  width: max-content;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 4px 24px;
  gap: 18px;
  font-size: 14px;
  font-weight: 600;
  background-color: #1f599c90;
  color: white;
  border-radius: 18px;
  right: 32px;
  bottom: 32px;
  z-index: 99999999;
  text-decoration: none;
}

.noRadius {
  border-radius: 0px !important;
}

.fontColorHover {
  color: #374151 !important;
  font-weight: 600 !important;
}

.fontColorHover:hover {
  color: #1f599c !important;
  font-weight: 600 !important;
}

.fontJakarta {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.bear-react-carousel__pagination-group {
  margin-top: 30px;
}

.noMargin {
  margin: 0px !important;
}

.fontW900 {
  font-weight: 900 !important;
}

.fontW700 {
  font-weight: 700 !important;
}

.fontW400 {
  font-weight: 400 !important;
}

.fontS40 {
  font-size: 40px !important;
}

.fontS32 {
  font-size: 32px !important;
}

.fontS18 {
  font-size: 18px !important;
}

.textkontenSPM {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cleanbackground {
  background-color: #1f599c !important;
}

.colorText {
  color: #374151 !important;
}

.colorTextLight {
  color: #9a9ea6 !important;
}

.buttonPartner {
  padding: 12px 32px;
}

.widthPartner {
  width: 50% !important;
}

.footero {
  width: 30%;
  gap: 18px;
  margin: 18px;
  text-align: justify;
}

.cardProduct {
  width: 25% !important;
  border-radius: 20px !important;
}

.titleProduct {
  font-size: 18px !important;
}

.textProduct {
  margin-top: 38px !important;
  font-size: 14px !important;
}

.buttonProduct {
  margin-bottom: 18px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}

.titleAbout {
  font-size: 36px;
}
.textAbout {
  line-height: 48px !important;
  font-size: 24px !important;
  font-weight: 500 !important;
}

@media (max-width: 600px) {
  .cardProduct {
    width: 90% !important;
  }

  .titleAbout {
    font-size: 24px;
  }
  .textAbout {
    line-height: 24px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    z-index: 1;
  }

  .textkontenSPM {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }

  .fontS40 {
    font-size: 32px !important;
  }

  .widthPartner {
    width: 80% !important;
  }

  .buttonPartner {
    padding: 8px !important;
    width: 100%;
    align-self: center;
  }

  .footero {
    width: 85%;
    gap: 18px;
    margin: 18px;
    text-align: justify;
    align-items: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
